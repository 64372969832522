<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="关键字">
					<el-input v-model="searchForm.name" placeholder="姓名/手机号码" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData()">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增会员</el-button>
					<el-button @click="importMember.dialogVisible = true" icon="el-icon-upload2">导入会员</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<!-- <el-table-column prop="member_number" label="会员ID" width="100"></el-table-column> -->
			<el-table-column prop="username" label="会员昵称" width="200">
				<template slot-scope="scope">
					<div class="flex align-center" v-if="scope.row.username">
						<el-image v-if="scope.row.avatar" class="cover" :src="scope.row.avatar" fit="cover"></el-image>
						{{scope.row.username}}
					</div>
					<div v-else>
						未绑定微信
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="real_name" label="真实姓名" width="100"></el-table-column>
			<el-table-column prop="phone" label="手机号码" width="150"></el-table-column>
			<el-table-column prop="balance" label="账户余额" width="150">
				<template slot-scope="scope">
					{{scope.row.balance}}
					<el-button icon="el-icon-edit" circle size="mini" @click="showChangeAmount(scope.row)"></el-button>
				</template>
			</el-table-column>
			<el-table-column prop="parent_balance" label="账户总余额" width="100"></el-table-column>
			<el-table-column prop="cumulative_recharge" label="累计充值" width="100"></el-table-column>
			<el-table-column prop="first_visite_at" label="访问时间">
				<template slot-scope="scope">
					<div>首次访问：{{scope.row.first_visite_at}}</div>
					<div>最近访问：{{scope.row.last_visite_at}}</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作">
				<template slot-scope="scope">
					<el-button size="mini" @click="$router.push(`/member/detail/${scope.row.id}`)">详情</el-button>
					<el-button size="mini" @click="$router.push('/member/groups/'+scope.row.id)">套餐</el-button>
					<el-button size="mini" @click="$router.push('/member/balanceLog/'+scope.row.id)">余额记录</el-button>
					<el-button size="mini" @click="$router.push('/member/account/'+scope.row.id)">账户明细</el-button>
					<el-popconfirm title="删除后数据不可恢复,确定删除吗？" @confirm="delVip(scope.row)">
						<el-button slot="reference" type="danger" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
		<!-- 账户调整 -->
		<el-dialog title="账户调整" :visible.sync="amountDialog.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false">
			<div>
				<el-form ref="form" :model="amountDialog.amount" :rules="rules" label-width="80px">
					<el-form-item label="账户余额">
						{{amountDialog.amount.balance|toFixed2}}
					</el-form-item>
					<el-form-item label="调整为" prop="after" :rules="rules.required">
						<el-input-number v-model="amountDialog.amount.after" :precision="2"></el-input-number>
					</el-form-item>
					<el-form-item label="备注" prop="remarks">
						<el-input v-model="amountDialog.amount.remarks"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="amountDialog.dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveAmount()" :loading="amountDialog.btnLoading">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 导入会员 -->
		<el-dialog title="导入会员" :visible.sync="importMember.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
			<div v-loading="importMember.loading">
				<el-input placeholder="请选择文件" readonly v-model="importMember.file">
					<el-upload slot="append" class="upload-demo" ref="upload" :action="importMember.action"
						:show-file-list="false" :on-success="importMemberSuccess" :on-error="importMemberError"
						:before-upload="beforeimportMember" :headers="importMember.headers">
						<el-button slot="trigger" size="small" type="primary">导入文件</el-button>
					</el-upload>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="text" @click="downloadImportExcel()">下载导入模板</el-button>
				<el-button type="primary" @click="importMember.dialogVisible = false" :loading="importMember.loading">取
					消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import rules from '../../common/common.rules.js'
	import axios from 'axios'
	export default {
		data() {
			return {
				rules,
				// 账户调整
				amountDialog: {
					dialogVisible: false,
					btnLoading: false,
					amount: {
						id: null,
						balance: '', // 账户余额
						after: '', // 修改后的余额
						remarks: '' // 备注
					},
				},
				importMember: {
					dialogVisible: false,
					loading: false,
					action: axios.defaults.baseURL + this.$api.common.IMPORT_MEMBER_URL,
					headers: {
						Authorization: null
					},
					file: '' // 文件地址
				},
				loading: false,
				tableData: [],
				searchForm: {
					name: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
			// vuex 是minxin 进来的 所以在data中访问不到，需要另行设置
			this.importMember.headers.Authorization = this.vuex_token;
		},
		methods: {
			// 下载模板
			downloadImportExcel() {
				if (this.vuex_project === 'MOMEN么么侠') {
					window.open('https://momenapi.memexia.com/uploads/memberexcel/商铺会员导入模板.xlsx?v1')
				} else {
					window.open('https://api.leishihk.com/uploads/memberexcel/商铺会员导入模板.xlsx?v1')
				}
			},
			// 导入会员成功
			importMemberSuccess(res) {
				console.log(res)
				this.$message.success('导入成功')
				this.importMember.dialogVisible = false;
				this.importMember.loading = false;
				this.loadData()
			},
			// 导入失败
			importMemberError() {
				this.importMember.loading = false;
				this.$message.error('导入失败')
			},
			beforeimportMember(file) {
				let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)
				const extension = fileName === 'xls'
				const extension2 = fileName === 'xlsx'
				if (!extension && !extension2) {
					this.$message({
						message: '上传文件只能是 xls、xlsx格式!',
						type: 'warning'
					});
					return false
				}
				this.importMember.loading = true;
			},

			showChangeAmount(item) {
				this.amountDialog.dialogVisible = true;
				this.amountDialog.amount.balance = item.balance;
				this.amountDialog.amount.after = item.balance;
				this.amountDialog.amount.remarks = '';
				this.amountDialog.amount.id = item.id;
				console.log(item)
			},
			saveAmount() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.amountDialog.btnLoading = true;
						this.$api.member.changeMemberBalance({
							id: this.amountDialog.amount.id,
							balance: this.amountDialog.amount.after,
							remark: this.amountDialog.amount.remarks
						}).then(res => {
							this.amountDialog.btnLoading = false;
							this.amountDialog.dialogVisible = false;
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`操作成功`);
								this.loadData()
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							this.amountDialog.btnLoading = false;
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			toEdit(user) {
				if (user) {
					// 编辑
					this.$router.push(`/member/edit/${user.id}`);
				} else {
					// 新增
					this.$router.push("/member/edit");
				}
			},
			delVip(item) {//删除未绑定微信的会员
				const loading = this.$loading()
				this.$api.member.delVip({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						// 保存成功
						this.$message.success(`操作成功`);
						this.loadData()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			loadData() {
				this.loading = true;
				this.$api.member.getMembers(this.searchForm).then().then(res => {
					console.log(res)
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.count;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
